import { fetchOrderType, fetchOrderTypes, updateOrderType } from './actions';
import { call, put, takeEvery } from 'redux-saga/effects';
import { showToastError, showToastSuccess } from 'helpers/utils/toast';
import {
  getOrderTypeRequest,
  getOrderTypesRequest,
  // createOrderTypeRequest,
  updateOrderTypeRequest,
} from 'helpers/api-requests/admin';
import i18n from 'i18n';

function* orderTypeFetchSaga({ payload }) {
  try {
    const response = yield call(getOrderTypeRequest, payload);
    if (response.success) {
      yield put(fetchOrderType.success(response.data));
    } else {
      yield put(fetchOrderType.failure(response));
      showToastError(response.message);
    }
  } catch (error) {
    yield put(fetchOrderType.failure(error));
  }
}

function* orderTypesFetchSaga({ payload }) {
  try {
    const response = yield call(getOrderTypesRequest, payload);
    if (response.success) {
      yield put(fetchOrderTypes.success(response.data));
    } else {
      yield put(fetchOrderTypes.failure(response));
      showToastError(response.message);
    }
  } catch (error) {
    showToastError(error);
    yield put(fetchOrderTypes.failure(error));
  }
}

// function* orderTypeCreateSaga({ payload }) {
//     try {
//       const response = yield call(createOrderTypeRequest, payload.order);
//       if (response.success) {
//         yield put(updateOrderType.success(response.data));

//         if (payload.redirectPath) {
//           if (
//             payload.redirectPath === '/invoices/' &&
//             response.data.bills?.length > 0
//           ) {
//             const invoice = response.data.bills.at();

//             return payload.push(`${payload.redirectPath}${invoice.id}`, {
//               fromOrder: true,
//             });
//           }

//           payload.push(payload.redirectPath);
//         } else {
//           payload.push(`/orders/${response.data.id}`);
//         }
//       } else {
//         yield put(updateOrderType.failure(response));
//         showToastError(response.message);
//       }
//     } catch (error) {
//       yield put(updateOrderType.failure(error));
//       showToastError(i18n.t('notifications.orderTypeError'));
//     }
//   }

function* orderTypeUpdateSaga({ payload }) {
  try {
    const response = yield call(updateOrderTypeRequest, {
      id: payload.id,
      orderType: payload.orderType,
    });

    if (response.success) {
      yield put(updateOrderType.success(response.data));
      showToastSuccess(i18n.t('notifications.orderTypeUpdated'));
    } else {
      yield put(updateOrderType.failure(response));
      showToastError(response.message);
    }
  } catch (error) {
    yield put(updateOrderType.failure(error));
    showToastError(i18n.t('notifications.orderTypeError'));
  }
}

function* orderTypesSaga() {
  yield takeEvery(fetchOrderType, orderTypeFetchSaga);
  yield takeEvery(fetchOrderTypes, orderTypesFetchSaga);
  yield takeEvery(updateOrderType, orderTypeUpdateSaga);
  // yield takeEvery(createOrderType, orderTypeCreateSaga);
}

export default orderTypesSaga;
