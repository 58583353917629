import {
  StyledButtonGray,
  StyledColBold,
  StyledField,
  BlueLink,
} from 'components/Common';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Label } from 'reactstrap';
import { DeliveryEdit } from './DeliveryEdit';
import { getShipmentLabelPdf } from 'helpers/downloadDocument';

export const DeliveryCard = ({
  delivery,
  index,
  handleOpenModal,
  setDeliveries,
  deliveries,
}) => {
  const { t } = useTranslation();
  const [isOpenEditMode, setIsOpenEditMode] = useState(false);

  const handleEditDelivery = () => {
    setIsOpenEditMode(true);
  };

  const handleCloseEditMode = () => {
    setIsOpenEditMode(false);
  };

  return (
    <>
      <StyledField
        key={index}
        className="p-3 d-flex mt-3 w-100 flex-lg-nowrap flex-wrap"
      >
        <Col xs={12} lg={7} xl={9}>
          <Label>{`${t('delivery.delivery')} ${index + 1}`}</Label>

          <FormGroup row>
            <Col lg={4} xs={6}>
              <span>{t('delivery.dpsMethod')}</span>
            </Col>

            <StyledColBold className="d-flex flex-column" lg={8} xs={6}>
              <p className="m-0">
                {delivery.providerDetail?.carrier_display ??
                  delivery.providerDetail?.label}
              </p>

              {delivery.methodDetail && (
                <p className="fw-normal m-0 text-muted">
                  {delivery.methodDetail.method_display}
                </p>
              )}
            </StyledColBold>
          </FormGroup>

          {delivery.number && (
            <FormGroup row>
              <Col lg={4} xs={6}>
                <span>{t('delivery.trackingNumber')}</span>
              </Col>

              <StyledColBold xs={6} lg={8}>
                {delivery.number}
              </StyledColBold>
            </FormGroup>
          )}

          {delivery.from?.city && (
            <>
              <FormGroup row>
                <Col lg={4} xs={6}>
                  <span>{t('delivery.from')}</span>
                </Col>

                <StyledColBold xs={6} lg={8}>
                  {delivery.from.city}
                </StyledColBold>
              </FormGroup>

              <FormGroup row>
                <Col lg={4} xs={6}>
                  <span>{t('delivery.to')}</span>
                </Col>

                <StyledColBold xs={6} lg={8}>
                  {delivery.to.city}
                </StyledColBold>
              </FormGroup>
            </>
          )}

          {delivery.shipmentLabel && (
            <>
              <FormGroup row>
                <Col lg={4} xs={6}>
                  <span>{t('delivery.shippingLabel')} </span>
                </Col>

                <StyledColBold xs={6} lg={8}>
                  <BlueLink onClick={() => getShipmentLabelPdf(delivery)}>
                    {t('documents.download')}
                  </BlueLink>
                </StyledColBold>
              </FormGroup>
            </>
          )}

          {delivery.comment && (
            <FormGroup row>
              <Col lg={4} xs={6}>
                <span>{t('common.comment')}</span>
              </Col>

              <StyledColBold xs={6} lg={8}>
                {delivery.comment}
              </StyledColBold>
            </FormGroup>
          )}
        </Col>

        {!isOpenEditMode && (
          <Col
            xs={12}
            className="d-flex h-100 justify-content-between gap-2 align-items-start"
            lg={5}
            xl={3}
          >
            <StyledButtonGray
              className="w-50"
              onClick={() => handleEditDelivery(index)}
              disabled={!delivery.editable}
            >
              <i className="bx bxs-edit-alt" />
              {t('common.edit')}
            </StyledButtonGray>

            <StyledButtonGray
              className="w-50 d-flex align-items-center justify-content-center"
              onClick={() => handleOpenModal(delivery.id)}
              disabled={!delivery.editable}
            >
              <i className="bx bxs-trash" />
              {t('common.delete')}
            </StyledButtonGray>
          </Col>
        )}
      </StyledField>

      {isOpenEditMode && (
        <DeliveryEdit
          deliveries={deliveries}
          setDeliveries={setDeliveries}
          handleCloseEditMode={handleCloseEditMode}
          delivery={delivery}
          index={index}
        />
      )}
    </>
  );
};
