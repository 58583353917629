import { createReducer } from 'helpers/store/createReducer';
import { combineReducers } from 'redux';
import {
  fetchOrderType,
  fetchOrderTypes,
  updateOrderType,
  // createOrderType
} from './actions';

const initState = {
  orderTypeDetail: {
    orderType: null,
    isLoading: false,
  },
  orderTypes: {
    orderTypes: [],
    isLoading: false,
  },
};

const orderTypes = createReducer(initState, {
  [fetchOrderType]: state => ({
    ...state,
    orderTypeDetail: {
      ...state.orderTypeDetail,
      isLoading: true,
    },
  }),
  [fetchOrderType.success]: (state, action) => ({
    ...state,
    orderTypeDetail: {
      ...state.orderTypeDetail,
      orderType: action.payload,
      isLoading: false,
    },
  }),
  [fetchOrderType.failure]: (state, action) => ({
    ...state,
    orderTypeDetail: {
      ...state.orderTypeDetail,
      error: action.payload,
      isLoading: false,
    },
  }),

  [fetchOrderTypes]: state => ({
    ...state,
    orderTypes: {
      ...state.orderTypes,
      isLoading: true,
    },
  }),
  [fetchOrderTypes.success]: (state, action) => ({
    ...state,
    orderTypes: {
      ...state.orderTypes,
      orderTypes: action.payload.results,
      isLoading: false,
    },
  }),
  [fetchOrderTypes.failure]: (state, action) => ({
    ...state,
    orderTypes: {
      ...state.orderTypes,
      error: action.payload,
      isLoading: false,
    },
  }),

  // [createOrderType]: state => ({
  //   ...state,
  //   isSubmitting: true,
  // }),

  // [createOrderType.success]: (state, action) => ({
  //   ...state,
  //   orderType: action.payload,
  //   alert: {
  //     alertMessage: null,
  //     isOpen: false,
  //     type: null,
  //   },
  //   isSubmitting: false,
  // }),

  // [createOrderType.failure]: state => ({
  //   ...state,
  //   isSubmitting: false,
  // }),

  [updateOrderType]: state => ({
    ...state,
    isLoading: true,
  }),
  [updateOrderType.success]: (state, action) => ({
    ...state,
    orderType: action.payload,
    isLoading: false,
  }),
  [updateOrderType.failure]: state => ({
    ...state,
    isLoading: false,
  }),
});

export default combineReducers({
  orderTypes,
});
