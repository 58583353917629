import { Select } from 'components/Common';
import { VisaDurationSelector } from 'components/VisaSelectors/VisaDurationSelector';
import { VisaTypeSelector } from 'components/VisaSelectors/VisaTypeSelector';
import { VisaUrgencySelector } from 'components/VisaSelectors/VisaUrgencySelector';
import { useFormikContext } from 'formik';
import { isUserEmployee, isUserOperator } from 'helpers/jwt_helper';
import i18n from 'i18n';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { ApplicantAddressForm } from './ApplicantAddressForm';
import { ApplicantUPSForm } from './ApplicantUPSForm';
import { NO, YES, YES_NO_OPTIONS } from 'common/data/constants';
import { getProfileState } from 'components/CommonForBoth/TopbarDropdown/ProfileMenu';
import { useSelector } from 'react-redux';
import { ModalConfirmDelete } from 'components/Common/ModalConfirmDelete';
import { StyledInput } from 'components/Common/FormikInput';
import styled from 'styled-components';

export const DOCS_RECEIVED_BY_CHOICES = [
  { label: i18n.t('order.email'), value: 'email' },
  { label: i18n.t('order.personal'), value: 'personal' },
  { label: i18n.t('order.wizard'), value: 'wizard' },
  { label: i18n.t('order.post'), value: 'post' },
  { label: i18n.t('order.courierDelivery'), value: 'courier_delivery' },
  { label: i18n.t('order.courierPickup'), value: 'courier_pickup' },
];

export const PREVIOUS_VISA_STATE = [
  { label: i18n.t('visa.no'), value: 'no' },
  { label: i18n.t('visa.samePassport'), value: 'yes_same_passport' },
  { label: i18n.t('visa.otherPassport'), value: 'yes_other_passport' },
];

export const DELIVERY_INDIVIDUAL_METHODS = [
  { label: i18n.t('order.takeMy'), value: 'delivery_method_pickup' },
  {
    label: i18n.t('order.anotherApplicant'),
    value: 'delivery_method_with_applicant',
  },
  { label: i18n.t('order.toAddress'), value: 'delivery_method_to_address' },
  { label: i18n.t('order.email'), value: 'delivery_method_email' },
];

const StyledHelpText = styled(Label)`
  font-size: 12px;
  font-weight: 100;
  color: red;
`;

export const ApplicantMainInfo = ({
  index,
  currentVisa,
  visaTypes,
  useFieldPreviousVisa,
  useFieldPreviousUssr,
  orderTypeFieldsSettings,
}) => {
  const { t } = useTranslation();
  const formik = useFormikContext();

  const currentApplicant = formik.values.applicants?.[index];
  const applicantDetail = currentApplicant?.applicantDetail;
  const profile = useSelector(getProfileState);
  const [
    isOpenEmployeeChangeParamsWarning,
    setIsOpenEmployeeChangeParamsWarning,
  ] = useState(false);
  const [warningSeen, setWarningSeen] = useState(false);

  // const visa_order_require_residency =
  //   applicantDetail?.visaOrderRequireResidency ??
  //   applicantDetail.passport.visa_order_require_residency;
  const applicantHasResidency = currentApplicant?.hasResidency === YES;
  // const showBlock =
  // !visa_order_require_residency ||
  // (visa_order_require_residency && applicantHasResidency);
  /*
  for Visa Order type require residency depends on visa type,
  other order types it depends on enable residency 
  */
  const require_residency = orderTypeFieldsSettings?.enableVisaType
    ? currentVisa?.require_residency &&
      (applicantDetail.passport.visa_order_require_residency ||
        applicantDetail.requireResidency)
    : orderTypeFieldsSettings?.enableResidency &&
      (applicantDetail.passport.visa_order_require_residency ||
        applicantDetail.requireResidency);

  const showBlock =
    !require_residency || (require_residency && applicantHasResidency);

  const isVisibleDurationSelect = useMemo(() => {
    const isVisible = currentVisa?.durations.length > 0 || !currentVisa;

    return isVisible;
  }, [currentVisa]);

  const isDisabledFields = useMemo(
    () => !formik.values.orderDetail?.isEditable,
    [formik.values.orderDetail?.isEditable],
  );

  const services = useMemo(
    () =>
      formik.values.applicants[index].services.filter(
        service => service.is_invoice_locked,
      ),
    [formik.values.applicants[index].services],
  );

  const isApplicantInWork = useMemo(
    () =>
      formik.values.applicants[index].services.some(
        service => service.is_invoice_locked,
      ),
    [formik.values.applicants[index].services],
  );

  const showWarning =
    isUserEmployee &&
    formik.values.orderDetail?.createdBy &&
    formik.values.orderDetail?.createdBy?.username !== profile?.username &&
    !warningSeen;

  const resetRelatedFields = () => {
    if (showWarning) {
      setIsOpenEmployeeChangeParamsWarning(true);
    }
    formik.values.applicants?.[index].statusApplicant === 'draft' &&
      formik.setFieldValue(`applicants[${index}].firstDateEntry`, null);

    formik.values.applicants?.[index].statusApplicant === 'draft' &&
      formik.setFieldValue(
        `applicants[${index}].documentsToApplicantEstimatedAt`,
        null,
      );
    formik.setFieldValue(`applicants[${index}].services`, services);
  };

  const handleCloseEmployeeChangeParamsWarningModal = () => {
    setWarningSeen(true);
    setIsOpenEmployeeChangeParamsWarning(false);
  };

  return (
    <div className="mt-4">
      {orderTypeFieldsSettings?.enableDescription && (
        <FormGroup row className="align-items-center mb-4">
          <Col lg={4} xxl={2} className="d-flex align-items-center">
            <Label className="display-5 m-0">
              {t('orderTypes.orderDescription')}
            </Label>
          </Col>

          <Col lg={8} xxl={10}>
            <StyledInput
              placeholder="Please write down few words to describe your order"
              error={formik.errors?.applicants?.[index]?.description}
              type="textarea"
              maxLength={240}
              style={{ height: 80 }}
              value={currentApplicant.description}
              onChange={e =>
                formik.setFieldValue(
                  `applicants[${index}].description`,
                  e.target.value,
                )
              }
            />
            {formik.errors?.applicants?.[index]?.description && (
              <StyledHelpText>
                {formik.errors?.applicants?.[index]?.description}
              </StyledHelpText>
            )}
          </Col>
        </FormGroup>
      )}

      {/* Visa category */}

      {orderTypeFieldsSettings?.enableVisaType && (
        <FormGroup row className="align-items-center mb-4">
          <Col lg={4} xxl={2} className="d-flex align-items-center">
            <Label className="display-5 m-0">{t('order.visaCategory')}</Label>
          </Col>

          <Col lg={8} xxl={10}>
            <VisaTypeSelector
              visasTypes={visaTypes}
              value={currentApplicant.visaType}
              onChange={typeVisa => {
                formik.setFieldValue(
                  `applicants[${index}].visaType`,
                  typeVisa ? typeVisa?.value : null,
                );

                formik.setFieldValue(`applicants[${index}].visaDuration`, null);
                formik.setFieldValue(`applicants[${index}].visaUrgency`, null);

                resetRelatedFields();
              }}
              isDisabled={
                currentApplicant.statusApplicant !== 'draft' ||
                isDisabledFields ||
                isApplicantInWork
              }
              applicantNationality={
                currentApplicant.applicantDetail.citizenship
              }
              country={formik.values.client.country}
              error={formik.errors?.applicants?.[index]?.visaType}
            />
            {formik.errors?.applicants?.[index]?.visaType && (
              <StyledHelpText>
                {formik.errors?.applicants?.[index]?.visaType}
              </StyledHelpText>
            )}
          </Col>
        </FormGroup>
      )}

      {/* Do u have residency ? */}

      {require_residency && (
        <>
          <FormGroup row className="mb-0">
            <Col lg={4} xxl={2} className="d-flex align-items-center">
              <Label className="display-5 fw-500 d-flex align-items-center">
                {t('order.visaOrderRequireResidency')}
              </Label>
            </Col>
            <Col lg={5} xxl={5}>
              <Select
                classNamePrefix="select2-selection"
                className="w-100"
                options={YES_NO_OPTIONS}
                isDisabled={isDisabledFields || isApplicantInWork}
                value={YES_NO_OPTIONS.find(
                  item => item.value === currentApplicant?.hasResidency,
                )}
                onChange={event => {
                  formik.setFieldValue(
                    `applicants[${index}].hasResidency`,
                    event?.value,
                  );
                }}
              />
            </Col>
          </FormGroup>

          {currentApplicant?.hasResidency === NO && (
            <Row>
              <Col xs={12} className="alert alert-danger">
                {isUserOperator ? (
                  <p className="text-center">
                    {t('order.residencyOperatorErrorMsg')}
                  </p>
                ) : (
                  <>
                    <p className="text-center">
                      {t('order.residencyErrorMSg')}
                      <a
                        style={{ color: 'blue' }}
                        href="mailto:vssuport@visaoffice.eu"
                      >
                        vssuport@visaoffice.eu
                      </a>
                    </p>
                  </>
                )}
              </Col>
            </Row>
          )}
        </>
      )}

      {/* Valid term  */}

      {orderTypeFieldsSettings?.enableVisaType &&
        isVisibleDurationSelect &&
        showBlock && (
          <FormGroup row className="mb-4">
            <Col lg={4} xxl={2} className="d-flex align-items-center">
              <Label className="display-5 m-0">
                {t('order.validTermVisa')}
              </Label>
            </Col>

            <Col lg={8} xxl={10}>
              <VisaDurationSelector
                isDisable={
                  currentApplicant.statusApplicant !== 'draft' ||
                  isDisabledFields ||
                  isApplicantInWork
                }
                value={currentApplicant.visaDuration}
                visaType={currentApplicant.visaType}
                onChange={visaDuration => {
                  formik.setFieldValue(
                    `applicants[${index}].visaDuration`,
                    visaDuration ? visaDuration?.value : null,
                  );
                  resetRelatedFields();
                }}
                currentVisaTypes={visaTypes}
                error={formik.errors?.applicants?.[index]?.visaDuration}
              />
              {formik.errors?.applicants?.[index]?.visaDuration && (
                <StyledHelpText>
                  {formik.errors?.applicants?.[index]?.visaDuration}
                </StyledHelpText>
              )}
            </Col>
          </FormGroup>
        )}

      {/* VS processing time  */}

      {showBlock && (
        <Row className="mb-4">
          <Col lg={4} xxl={2} className="d-flex align-items-center">
            <Label className="display-5 m-0">{t('order.visaProcessing')}</Label>
          </Col>

          <Col lg={5} xxl={5}>
            <VisaUrgencySelector
              currentVisaTypes={visaTypes}
              isDisable={
                currentApplicant.statusApplicant !== 'draft' ||
                isDisabledFields ||
                isApplicantInWork
              }
              value={currentApplicant.visaUrgency}
              visaType={currentApplicant.visaType}
              onChange={typeVisa => {
                formik.setFieldValue(
                  `applicants[${index}].visaUrgency`,
                  typeVisa ? typeVisa.value : null,
                );
                resetRelatedFields();
              }}
              placeholder={t('order.visaProcessingPlaceholder')}
              error={formik.errors?.applicants?.[index]?.visaUrgency}
            />
            {formik.errors?.applicants?.[index]?.visaUrgency && (
              <StyledHelpText>
                {formik.errors?.applicants?.[index]?.visaUrgency}
              </StyledHelpText>
            )}
          </Col>
        </Row>
      )}

      {/* Previous visa  */}

      {showBlock && (
        <FormGroup row className="mb-4">
          {useFieldPreviousVisa && (
            <>
              <Col lg={4} xxl={2} className="d-flex align-items-center">
                <Label className="display-5 fw-500 m-0" check>
                  {t('order.previousVisa')}
                </Label>
              </Col>

              <Col lg={8} xxl={10}>
                <Select
                  className="w-100"
                  options={PREVIOUS_VISA_STATE}
                  isDisabled={isDisabledFields || isApplicantInWork}
                  value={PREVIOUS_VISA_STATE.find(
                    item => item.value === currentApplicant?.previusVisa,
                  )}
                  onChange={event => {
                    formik.setFieldValue(
                      `applicants[${index}].previusVisa`,
                      event?.value,
                    );
                    formik.setFieldValue(
                      `applicants[${index}].prevExpirationDate`,
                      null,
                    );
                  }}
                  classNamePrefix="select2-selection"
                />
              </Col>
            </>
          )}
        </FormGroup>
      )}

      {/* Previous USSR  */}

      {useFieldPreviousUssr && showBlock && (
        <FormGroup row className="mb-0">
          <Col>
            <Label className="display-5 fw-500 d-flex align-items-center">
              <Input
                type="checkbox"
                disabled={isDisabledFields || isApplicantInWork}
                checked={currentApplicant.previousUssr}
                onChange={event =>
                  formik.setFieldValue(
                    `applicants[${index}].previousUssr`,
                    event.target.checked,
                  )
                }
                className="me-2 my-0"
              />

              {t('order.previousUssr')}
            </Label>
          </Col>
        </FormGroup>
      )}

      <hr className="mt-4 mb-4" />

      {/* Documents received be  */}

      {isUserOperator && showBlock && (
        <Row className="mb-4">
          <Col lg={4} xxl={2} className="d-flex align-items-center">
            <Label className="display-5 m-0">
              {t('order.documentsReceivedBy')}
            </Label>
          </Col>

          <Col lg={5} xxl={5}>
            <Select
              classNamePrefix="select2-selection"
              options={DOCS_RECEIVED_BY_CHOICES.filter(
                method => method.value !== currentApplicant.documentsReceivedBy,
              )}
              isDisabled={isDisabledFields || isApplicantInWork}
              value={DOCS_RECEIVED_BY_CHOICES.find(
                item => item.value === currentApplicant?.documentsReceivedBy,
              )}
              onChange={event => {
                formik.setFieldValue(
                  `applicants[${index}].documentsReceivedBy`,
                  event?.value,
                );
              }}
            />
          </Col>
          <hr className="mt-4 mb-2" />
        </Row>
      )}

      {isOpenEmployeeChangeParamsWarning && (
        <ModalConfirmDelete
          handleCloseModal={handleCloseEmployeeChangeParamsWarningModal}
          textHeader="common.warning"
          textBody="order.employeeChangeParamsWarning"
          typeButtonConfirm="save"
          isOpenModal={isOpenEmployeeChangeParamsWarning}
          cancelText="Ok"
          showBtn={false}
        />
      )}

      {/* Delivery method  */}
      {showBlock && (
        <ApplicantUPSForm
          index={index}
          currentApplicant={currentApplicant}
          isApplicantInWork={isApplicantInWork}
        />
      )}

      {orderTypeFieldsSettings?.enableDeliveryMethod && showBlock && (
        <ApplicantAddressForm
          index={index}
          currentApplicant={currentApplicant}
          isApplicantInWork={isApplicantInWork}
        />
      )}
    </div>
  );
};
